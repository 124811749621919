import React from "react";
import "./App.css";
import useTracklist from "./hooks/useTracklist";
import { TracklistItem } from "./components/TracklistItem";

function App() {
  const { tracklist } = useTracklist();

  return (
    <div className="App">
      {tracklist?.map((item) => <TracklistItem item={item} />)}
    </div>
  );
}

export default App;
