import useSWR from "swr";
import { TracklistItem } from "../types";

// @ts-ignore
const fetcher = (...args) => fetch(...args).then((res) => res.json());

export default function useTracklist() {
  const { data, error, isLoading } = useSWR<TracklistItem[]>(
    "/api/tracklist",
    fetcher,
  );

  return { tracklist: data, error, isLoading };
}
