import React from "react";
import { TracklistItem as ItemType } from "../types";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";

interface Props {
  item: ItemType;
}

export const TracklistItem = ({ item }: Props) => {
  return (
    <Card sx={{ display: "flex" }}>
      <CardMedia
        component="img"
        sx={{ width: 120 }}
        image={`/album-covers/${item.id}.png`}
      />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography component="div" variant="h5">
            {item.title}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
          >
            {item.artist}
          </Typography>
          <Typography variant="subtitle2" color="text.secondary">
            {dayjs(item.timestamp).format("HH:mm")}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  );
};
